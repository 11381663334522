import { PropertyType as PropertyTypeEnum } from 'consts/enums/property';
import usePropertyMainPhoto from 'hooks/logics/usePropertyMainPhoto';
import Image from 'next/image';
import { HTMLAttributes } from 'react';
import { PhotoType, PropertyAddress, PropertyType } from 'types/property-types';
import { PHOTO_SIZE, sizeSpecificImages } from 'utils/url_utils';
import classes from './index.module.scss';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'property'> {
  property: Partial<PropertyType> & {
    ticker: string;
    address: PropertyAddress;
    coordinates: {
      longitude: number;
      latitude: number;
    };
    photos: PhotoType[];
    propertyType: PropertyTypeEnum;
  };
}

const MapImagePropertyCard = ({ property }: Props) => {
  const { photo, isOutsourced } = usePropertyMainPhoto(property);
  const finalPhoto = isOutsourced
    ? photo
    : photo && sizeSpecificImages([photo], PHOTO_SIZE.THUMBNAIL)?.[0];

  return (
    <div className={classes.imageContainer}>
      {finalPhoto && (
        <Image
          className={classes.image}
          alt={property?.ticker}
          src={finalPhoto}
          width={220}
          height={110}
        />
      )}
    </div>
  );
};

export default MapImagePropertyCard;
