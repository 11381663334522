import MapDetailsPropertyCard from 'components/properties/PropertyCard/MapPropertyCard/MapDetailsPropertyCard';
import MapImagePropertyCard from 'components/properties/PropertyCard/MapPropertyCard/MapImagePropertyCard';
import { PropertyType as PropertyTypeEnum } from 'consts/enums/property';
import Link from 'next/link';
import { HTMLAttributes } from 'react';
import { PhotoType, PropertyAddress, PropertyType } from 'types/property-types';
import {
  encodeTicker,
  propertyAddressPathGenerator,
} from 'utils/property_utils';
import classes from './index.module.scss';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'property'> {
  property: Partial<PropertyType> & {
    ticker: string;
    address: PropertyAddress;
    coordinates: {
      longitude: number;
      latitude: number;
    };
    photos: PhotoType[];
    propertyType: PropertyTypeEnum;
  };
  price?: number;
}

const MapPropertyCard = ({ property, price }: Props) => {
  const path = propertyAddressPathGenerator(property.address);
  return (
    <Link href={`/property/${path}/${encodeTicker(property.ticker)}`}>
      <div className={classes.container}>
        <MapImagePropertyCard property={property} />
        <MapDetailsPropertyCard
          property={property}
          price={price}
        />
      </div>
    </Link>
  );
};

export default MapPropertyCard;
