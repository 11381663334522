import classNames from 'classnames';
import Money from 'components/generics/texts/Money';
import Typography from 'components/generics/texts/Typography';
import { PROPERTY_TYPE_TO_TEXT } from 'components/properties/attributes/PropertyType/utils';
import { PropertyType as PropertyTypeEnum } from 'consts/enums/property';
import useStockPrice from 'hooks/requests/properties/useStockPrice';
import { HTMLAttributes } from 'react';
import { PhotoType, PropertyAddress, PropertyType } from 'types/property-types';
import classes from './index.module.scss';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'property'> {
  property: Partial<PropertyType> & {
    ticker: string;
    address: PropertyAddress;
    coordinates: {
      longitude: number;
      latitude: number;
    };
    photos: PhotoType[];
    propertyType: PropertyTypeEnum;
  };
  price?: number;
}

const MapDetailsPropertyCard = ({ property, className, price }: Props) => {
  const propertyTypeText =
    PROPERTY_TYPE_TO_TEXT[property?.propertyType] || property?.propertyType;
  const { [property?.ticker]: sharePrice } = useStockPrice(
    property?.ticker,
    false
  );

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.topSection}>
        <Typography className={classNames(classes.title, classes.ellipsis)}>
          {property?.address.houseNumber} {property?.address.street}
        </Typography>
        <Money
          withDecimal={true}
          className={classNames(classes.title, classes.stockPrice)}
          value={price || sharePrice}
        />
      </div>
      <div className={classes.bottomSection}>
        <Typography className={classes.subtitle}>
          {property?.address.city.name}, {property?.address.state.code} ∙{' '}
          {propertyTypeText}
        </Typography>
      </div>
    </div>
  );
};

export default MapDetailsPropertyCard;
